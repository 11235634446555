@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;

.program {
  &_card {
    position: relative;
    padding: 0.75rem 1.625rem;
    margin-bottom: 0.75rem;
    
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      height: 100%;
      width: 0.625rem;
      border-radius: 0.375rem 0 0 0.375rem;
      background-color: color.$v2-grey-5;
    }
  }
}
