@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;

.content {
  padding-bottom: 2rem;
  margin-bottom: 1.4375rem;
  border-bottom: 1px solid color.$v2-grey-5;

  span {
    white-space: unset !important; // temporary solution to rewrite styles that comes from CatalogService
  }

  @include dimension.tabletAndMobile {
    padding-bottom: 1.5rem;
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  &-child {
    margin-bottom: 1rem;
    @include dimension.tabletAndMobile {
      margin-bottom: 0.75rem;
    }
    h4 {
      margin-bottom: 0.75rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
