@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;

// box-shadow
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -moz-box-shadow: inset $top $left $blur $spread $color;
    box-shadow: inset $top $left $blur $spread $color;
  } @else {
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

// generic transform - do we need these mixins???? -VL
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin-offset($offsetX, $offsetY) {
  -moz-transform-origin: $offsetX $offsetY;
  -o-transform-origin: $offsetX $offsetY;
  -ms-transform-origin: $offsetX $offsetY;
  -webkit-transform-origin: $offsetX $offsetY;
  transform-origin: $offsetX $offsetY;
}

//transitions
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin listItemStyleSquare($color-code) {
  content: '';
  height: 5px;
  width: 5px;
  background-color: $color-code;
  position: absolute;
  display: block;
}

@mixin listItems() {
  ul {
    padding-left: 0;

    li {
      list-style: none;
      margin-bottom: 0.25rem;
      padding-left: 1rem;
      position: relative;

      &::before {
        @include listItemStyleSquare(color.$v2-into-blue-100);
        height: 5px;
        width: 5px;
        left: 0px;
        top: 0.5rem;
      }
    }
  }
}

@mixin defaultButton() {
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    cursor: pointer;
    position: relative;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin cta-button(
  $backgroundColor,
  $hoverBackgroundColor,
  $hoverBorderColor,
  $enabledActiveColor,
  $enabledActiveBackgroundColor,
  $enabledActiveBorderColor,
  $focusBackgroundColor,
  $focusBorderColor
) {
  background-color: $backgroundColor;

  &:hover {
    background-color: $hoverBackgroundColor;
    border-color: $hoverBorderColor;
  }

  &:focus {
    background-color: $focusBackgroundColor;
    border-color: $focusBorderColor;
  }

  &:disabled {
    pointer-events: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        &:focus {
          box-shadow: none;
        }
        color: $enabledActiveColor;
        background-color: $enabledActiveBackgroundColor;
        border-color: $enabledActiveBorderColor;
      }
    }
    &:not(.disabled).active {
      &:focus {
        box-shadow: none;
      }
      color: $enabledActiveColor;
      background-color: $enabledActiveBackgroundColor;
      border-color: $enabledActiveBorderColor;
    }
  }
}

@mixin defaultIconButton(
  $buttonWidth,
  $buttonHeight,
  $buttonMargin,
  $buttonPadding,
  $buttonPosition,
  $buttonPositionTop,
  $buttonPositionRight,
  $buttonBackgroundImage,
  $buttonBackgroundImageSize
) {
  width: $buttonWidth;
  height: $buttonHeight;
  position: $buttonPosition;
  border-radius: 0;
  border: none;
  background-image: url($buttonBackgroundImage);
  cursor: pointer;
  background-color: transparent;
  font-size: 0;
  top: $buttonPositionTop;
  right: $buttonPositionRight;
  margin: $buttonMargin;
  padding: $buttonPadding;
  opacity: 1;
  background-size: $buttonBackgroundImageSize;
  background-repeat: no-repeat;
  background-position: center center;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus {
    opacity: 1;
  }
}
