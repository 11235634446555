@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/fonts' as font;
@use '@/styles/utils/dimensions' as dimension;


.top-ranked-program-section {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.rank {
  &-card {
    padding: 0.625rem 0.75rem;
    height: 100%;
  }

  &-stat {
    height: 3.75rem;
    width: 3.75rem;
    @include font.fs-rem(1.5);
    color: color.$white;
    font-family: font.$font-ns-pro;
    text-align: center;
    border-radius: 50%;
  }

  &-content {
    padding-left: 0.75rem;

    p {
      margin-bottom: 0;
      margin-top: 0.75rem;
    }
  }
}

.ranking-grid {
  &-item {
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }
    @include dimension.desktop {
      margin-bottom: 0;
    }
  }
  @include dimension.desktop {
    display: grid;
    grid-auto-flow: column;
    grid-row-gap: 0.75rem;
    grid-column-gap: 1.875rem;
    grid-template-columns: 1fr 1fr;
  }
}
