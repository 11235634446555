// Utilities
@use './utils/fonts' as font;
@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;

// include all of Bootstrap
// https://getbootstrap.com/docs/5.1/customize/sass/
// https://sass-lang.com/documentation/at-rules/use#configuration
@use '~bootstrap/scss/bootstrap' with (
  // gutter padding fix for
  // container, row, col, you name it
  $grid-gutter-width: 1.875rem,
  // Grid breakpoints
  // Define the minimum dimensions at which your layout will change,
  // adapting to different screen sizes, for use in media queries.
  $grid-breakpoints: (
      xs: 0,
      sm: 576px,
      md: 768px,
      lg: 992px,
      xl: 1200px,
      xxl: 1400px,
      3xl: 1440px,
      e3xl: 1640px
    ),
  // Grid containers
  // Define the maximum width of `.container` for different screen sizes
  $container-max-widths: (
      sm: 540px,
      md: 720px,
      lg: 960px,
      xl: 1140px,
      xxl: 1320px,
      3xl: 1400px,
      e3xl: 1600px
    ),
  // modify global settings to enable negative margins
  // as by default its FALSE
  // https://getbootstrap.com/docs/5.1/utilities/spacing/#negative-margin
  $enable-negative-margins: true,
  // headings configurations
  $headings-font-weight: font.$fw-bold,
  $headings-font-family: font.$font-ns-pro,
  // CTA Buttons configurations
  $input-btn-font-family: font.$font-ns-pro,
  $input-btn-padding-y: 0rem,
  $input-btn-padding-x: 2rem,
  $input-btn-border-width: 0.125rem,
  $btn-font-weight: font.$fw-bold,
  $btn-border-radius: 0.25rem,
  // Links configurations, Style anchor elements
  $link-color: color.$v2-into-blue-100,
  $link-hover-color: color.$v2-into-blue-200
);

// utilities
@use './utils/dimensions' as dimension;
@use './utils/mixins' as mixin;
@use '@/styles/functions/z-index' as zIndex;
@use './utils/focus' as focus;
// end utilities

// partials styling
@use '@/styles/_partials/forms.module.scss' as form;
// end partials styling

html,
body {
  font-family: font.$font-lato;
}

body {
  min-height: 100%;
  height: 100vh;
  color: color.$copy-text;
}

main {
  letter-spacing: 0.031rem;
  position: relative;
  flex: 1;
}

#__next,
.root-layout {
  height: 100%;

  .page-section {
    flex: 1 1 auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;

    @include dimension.desktop {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}

a {
  word-break: normal;
  overflow-wrap: break-word;
  cursor: pointer;

  &:active {
    color: color.$v2-into-turquoise-200;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    border-radius: 4px;
  }
}

ul {
  list-style: square;
  padding-left: 1.5rem;
  margin: 1.25rem 0;

  li p {
    margin: 3px 0;
  }
}

.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.image-wrapper {
  div:first-child {
    vertical-align: middle;
  }
}

.body-bg-color {
  background-color: color.$v2-grey-9;
}

//Bootstrap "_position.scss" file don`t contain "position: -webkit-sticky" for supporting safari 12 in "sticky-top" class;
.sticky-top {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: zIndex.into-z-index('below', 'sidebar');
}

.top-3 {
  top: 3.15rem;
}

.z-index-cover {
  z-index: zIndex.into-z-index('cover', 'sidebar');
}

@include dimension.tabletAndMobile {
  .sticky-top-md-none {
    position: relative;
  }
  .z-index-md-reset {
    z-index: 1;
  }
}

.font-family-lato {
  font-family: font.$font-lato;
}

.font-family-neo-sans {
  font-family: font.$font-ns-pro;
}

.font-family-neo-sans-pro {
  font-family: font.$font-ns-pro;
}

.font-neo-sans-medium {
  @extend .font-family-neo-sans;
  font-weight: font.$fw-bold;
}

.bg-white {
  background-color: color.$white;
}

.rounded-border-6 {
  border-radius: 0.375rem;
}

.pre-wrap {
  white-space: pre-wrap;
}

hr {
  margin-top: 1rem;
  margin-bottom: 24px;

  @include dimension.desktop {
    margin-top: 12px;
  }
}

.top-bar {
  height: 1.875rem;
  background-color: color.$v2-grey-2;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-clip {
  overflow-x: clip;
}

.custom-page-template {
  margin-top: -1rem;
  margin-bottom: -1rem;

  @include dimension.desktop {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
}

.has-sidebar-nav {
  overflow-x: unset;
}

.nav {
  &.nav-tabs {
    border: none;
    padding-left: 1rem;

    @include dimension.desktop {
      padding-left: 1.5rem;
    }

    @include dimension.mobile() {
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 0;
        background: transparent;
      }
    }

    .nav-link {
      letter-spacing: 0.031rem;
      white-space: nowrap;
      margin-right: -1px;
      margin-bottom: -0.125rem;
      text-decoration: none;
      color: color.$v2-into-turquoise-100;
      @include font.fs-rem(0.875);
      font-family: font.$font-ns-pro;
      padding: 0.75rem;
      border-radius: 0.375rem 0.375rem 0 0;
      border-color: rgba(color.$v2-grey-5, 0.4);
      background-color: color.$v2-grey-9;
      font-weight: font.$fw-bold;

      @include dimension.desktop {
        margin-bottom: -1px;
        border-bottom: none;
        margin-right: 3px;
        padding: 0.688rem 1rem;
        @include font.fs-rem(1);
        font-weight: font.$fw-normal;
        @include mixin.box-shadow(0, -0.375rem, 0.625rem, 0, rgba(color.$v2-grey-5, 0.3), false);
      }

      &:focus {
        outline: none;
      }
      &.active {
        background-color: color.$white;
        position: relative;
        border-bottom-color: transparent;
      }
    }
  }
}

.tab-content {
  &-block {
    padding-top: 0.5rem;
    margin-left: -0.938rem;
    margin-right: -0.938rem;

    @include dimension.tabletAndMobile {
      .tab-content {
        border-radius: 0;
        box-shadow: none;
      }
    }

    @include dimension.desktop {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &-btn-group {
    .btn {
      font-family: font.$font-lato;
      font-weight: font.$fw-normal;
      @include font.fs-rem(0.875);
      line-height: 1.125rem;
      padding: 0 1rem;
      border-width: 1px;
    }

    padding-top: 0.5rem;
  }

  padding: 1.563rem 1rem;
  @extend .bg-white, .rounded-border-6, .element-default-style;

  @include dimension.desktop {
    padding: 1.563rem;
  }

  &-table {
    margin-top: 1.5rem;

    @include dimension.desktop {
      margin-top: 1rem;
    }

    &-col {
      padding: 0.625rem;
      border-right: 1px solid color.$v2-grey-5;
      border-left: 1px solid color.$v2-grey-5;

      + .tab-content-table-col {
        border-left: 0;
      }
    }

    &-head {
      background-color: color.$v2-grey-2;
      color: color.$white;
      .tab-content-table-col {
        border-color: color.$v2-grey-2;

        &:first-child {
          border-right-color: color.$v2-grey-5;
        }
      }
    }

    &-row {
      border-bottom: 1px solid color.$v2-grey-5;

      &:nth-child(even) {
        background-color: color.$v2-grey-9;
      }

      &:last-child {
        .tab-content-table-col {
          border-bottom: none;
        }
      }
    }
  }
  .tab-pane {
    .text-block {
      // to over-ride bootstrap class on atom :(
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.bg-white {
  background-color: color.$white;
}

.rounded-border-6 {
  border-radius: 0.375rem;
}

.text-color-primary {
  color: color.$v2-into-turquoise-100;
}

@include dimension.desktop {
  .pe-lg-7 {
    padding-right: 7rem !important; // to over-ride bootstrap default padding
  }
}

.element-default-style {
  border: 1px solid rgba(color.$v2-grey-5, 0.4);
  @include mixin.box-shadow(0, 0, 0.625rem, 0, rgba(color.$v2-grey-5, 0.5), false);

  @include dimension.desktop {
    @include mixin.box-shadow(0, 0.125rem, 0.625rem, 0, rgba(color.$v2-grey-5, 0.3), false);
  }
}

.calc-width-2rem {
  width: calc(100% - 2rem);
}

.rt-block {
  a {
    color: color.$v2-into-turquoise-100;

    &:visited {
      color: color.$v2-purple-100;
    }

    &:hover,
    &:visited:hover {
      color: color.$v2-into-turquoise-200;
    }

    &:active,
    &:active:hover,
    &:focus,
    &:focus-within,
    &:focus-visible,
    &:focus:hover,
    &:focus-within:hover,
    &:focus-visible:hover,
    &:visited:focus:hover,
    &:visited:focus-within:hover,
    &:visited:focus-visible:hover {
      color: color.$v2-into-turquoise-100;
      box-shadow: 0px 0px 0px 2px color.$v2-neutral-yellow-100;
      outline: none;
    }
  }
}

.image-wrapper {
  position: relative;
}

.text-color-muted {
  color: color.$v2-grey-3;
}
