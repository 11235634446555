@use '@/styles/utils/fonts' as font;
.button_group {
  font-family: font.$font-lato;
  font-weight: font.$fw-normal;
  @include font.fs-rem(0.875);
  line-height: 1.125rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
