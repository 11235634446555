@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/mixins' as mixin;

/* Global Form Styles */
.form-control, .form-select {
  padding: .563rem; 
  border: 1px solid color.$v2-grey-7;
  color: color.$copy-text;
  height: -moz-calc(1.5em + 1rem + 4px);
  height: calc(1.5em + 1rem + 4px);
  @include mixin.transition(all 0.25s ease-in-out);

  &:focus {
    color: color.$copy-text;
    background-color: color.$white !important;
    border-color: color.$v2-neutral-yellow-100;
    outline: 0;
    @include mixin.box-shadow(0, 0, 0, 2px, color.$v2-neutral-yellow-100, false);
  }
}

.was-validated {
  .form-control, .form-select {
    &:invalid:focus, &:valid:focus {
      color: color.$copy-text;
      background-color: color.$white;
      border-color: color.$v2-neutral-yellow-100;
      outline: 0;
      @include mixin.box-shadow(0, 0, 0, 2px, color.$v2-neutral-yellow-100, false);
    }

    &:invalid {
      padding-right: .563rem;
      background-image: unset;
    }
    
    &:valid {
      border: 1px solid color.$v2-grey-7;
    }
  }

  .form-select {    
    &:invalid, &:valid {
      padding-right: 2rem !important; 
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
      background-position: right 1rem center !important;
      background-size: 16px 12px !important;
    }
  }

  .form-check-input:invalid ~ .form-check-label,
  .form-check-input:valid ~ .form-check-label {
    color: color.$black;
  }
.form-select {
  padding: .563rem 2rem .563rem .563rem; 
  background-position: right 1rem center;
  
  &:invalid, &:valid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
    background-position: right 1rem center !important;
    background-size: 16px 12px !important;
  }
}
}

.form-control {
  &:valid {
    padding-right: .563rem !important;
    background-image: unset !important;
    background-position: unset !important;
    background-size: unset !important;
  }
}

.form-select {
  padding: .563rem 2rem .563rem .563rem; 
  background-position: right 1rem center;
}

.form-check-input {
  &:focus {
    color: color.$copy-text;
    border-color: color.$v2-neutral-yellow-100;
    outline: 0;
    @include mixin.box-shadow(0, 0, 0, 2px, color.$v2-neutral-yellow-100, false);
  }

  ~ .form-check-label {
    font-weight: 700;
  }
}

.form-label {
  font-weight: 700;
}
.custom-dropdown {
  .dropdown-wrapper {
    &:after {
      // -webkit-mask: url('/next-assets/icons/chevron-dropdown.svg') no-repeat 50% 50%;
      // mask: url('/next-assets/icons/chevron-dropdown.svg') no-repeat 50% 50%;
      // -webkit-mask-size: cover;
      // mask-size: cover;
      background-color: color.$v2-into-turquoise-100;
    }
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 2rem;
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}