@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;

.cost-breakdown-section {
  padding-bottom: 1rem;

  @include dimension.desktop {
    padding-bottom: 1.875rem;
    border-bottom: 1px solid color.$v2-grey-5;
    margin-bottom: 0.875rem;
  }
}

.has-cost-section {
  margin-top: 1.5rem;

  @include dimension.desktop {
    margin-top: 5.15rem;
  }
}
