@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;

.student_success {
  background-color: color.$v2-grey-9;
  padding: 1rem;

  @include dimension.desktop {
    padding: 1.5rem 1.75rem;
  }
}
