@use '@/styles/utils/dimensions' as dimension;

.accommodation_btn {
  margin-top: 2px;
  max-width: 100%;

  @include dimension.desktop {
    max-width: 360px;
  }
}
