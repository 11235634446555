@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;
@use '@/styles/utils/fonts' as font;
@use '@/styles/utils/mixins' as mixin;

.mobile-filter-section {
  margin-left: -0.938rem;
  margin-right: -0.938rem;
  margin-bottom: 0.938rem;
  border: 2px solid color.$v2-grey-7;

  .mobile-filter-toggle {
    background-color: color.$white;
    height: -moz-calc(1.5em + 1rem + 4px);
    height: calc(1.5em + 1rem + 4px);
    @include mixin.transition(all 0.25s ease-in-out);
    color: color.$v2-into-turquoise-100;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: font.$fw-bold;

    &::after {
      content: '';
      background-image: url('/next-assets/icons/caret-circle-down-regular.svg');
      background-size: 1.25rem 1.25rem;
      height: 1.25rem;
      width: 1.25rem;
      float: right;
      margin-top: 2px;
    }

    &:focus {
      color: color.$v2-into-turquoise-200;
      background-color: color.$white;
      border-color: color.$v2-neutral-yellow-100;
      outline: 0;
      @include mixin.box-shadow(0, 0, 0, 0.2rem, color.$v2-neutral-yellow-100, false);
    }

    @include dimension.smallMobile {
      padding-left: 12%;
      padding-right: 12%;
    }
  }
  .mobile-filter-options {
    height: 100%;

    > div {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-align: left;
      margin: 0;
      height: 100%;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
    }

    input[type='checkbox'] {
      + label {
        @include font.fs-rem(0.875);
        margin-bottom: 0.75rem;
        padding-left: 1.75rem;
      }

      &:checked {
        + label {
          &::before {
            border-color: color.$v2-into-turquoise-100;
            background-color: color.$v2-into-turquoise-100;
          }
        }
      }
    }
  }

  .mobile-filter-submit {
    padding: 1rem;
    background: color.$white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  select { 
    color: color.$v2-into-blue-100 !important;
  }
}
