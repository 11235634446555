@use '@/styles/utils/dimensions' as dimension;

.hta_title {
  color: var(--brand-color);
  padding-bottom: 0.375rem;
  border-bottom: 4px solid var(--brand-color);
  width: fit-content;
  margin-bottom: 1rem;

  @include dimension.tabletAndMobile {
    margin-bottom: 0.75rem;
  }
  svg {
    height: 1.5rem;
    color: var(--brand-color);
  }
  span {
    margin-left: 0.75rem;
  }
}
