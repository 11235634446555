@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;
@use '@/styles/utils/fonts' as font;
@use '@/styles/utils/focus' as focus;
@use '@/styles/utils/mixins' as mixin; 

.result-row {
  background-color: color.$white;
  border: 1px solid color.$v2-grey-5;
  border-radius: 0.5rem;
}

.course {
  border-radius: 0 0.5rem 0.5rem 0;
  padding: 1rem 0;

  @include dimension.desktop {
    padding: 1.5rem 0;
  }
}

.brand-location {

  span {
    position: relative;
    display: inline-block;
    padding-left: 0.575rem; 
    margin-left: 0.575rem;

    &::before {
      content: ""; 
      display: inline-block;
      height: 1rem; 
      width: 1px; 
      background-color: color.$v2-grey-5;
      position: absolute;
      left: 0; 
      top: 4px;
    }

  }
}

.program-row {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include dimension.tabletAndMobile {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid color.$v2-grey-5;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem; 
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    border-bottom: 1px solid color.$v2-grey-5;
    
    @include dimension.tabletAndMobile {
      margin-bottom: 1rem;
      padding: 0rem 1rem 1rem 1rem;
      border-bottom: 1px solid color.$v2-grey-5;
    }
  }
}

.search-results-accordion {
  text-decoration: none;

  &-item {
    text-decoration: none;
  }

  &-header {
    margin: 0;

    button {
      line-height: normal;
      margin: 0;
      font-family: font.$font-lato;
      font-weight: font.$fw-bold;
      width: auto;
      display: inline-flex; 
      position: relative; 
      padding-right: 0.875rem;
      color: color.$v2-into-turquoise-100 !important;

      &::after {
        box-sizing: border-box;
        height: 7px;
        width: 7px;
        border-style: solid;
        border-color: color.$v2-into-turquoise-100;
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        right: 0;
        @include mixin.transition(all 0.25s ease-in-out);
      }
      
      &[aria-expanded="true"] {
        &::after {
          top: 5px;
          transform: rotate(225deg);
        }
      }
    }
  }

  &-body {
    border-top: 1px solid color.$v2-grey-5;
  }
}