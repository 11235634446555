@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;

.program-row {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include dimension.tabletAndMobile {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid color.$v2-grey-5;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem; 
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    border-bottom: 1px solid color.$v2-grey-5;
    
    @include dimension.tabletAndMobile {
      margin-bottom: 1rem;
      padding: 0rem 1rem 1rem 1rem;
      border-bottom: 1px solid color.$v2-grey-5;
    }
  }
}