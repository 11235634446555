@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/fonts' as font;

.bar {
  background-color: color.$v2-grey-7;
  position: relative;
  height: 3.125rem;
  border-radius: 0.25rem;

  &_value {
    font-family: font.$font-ns-pro;
    position: absolute;
    background-color: color.$v2-into-turquoise-100;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    color: color.$white;
    border-radius: 0.25rem;

    .barText {
      padding: 0 1rem;
    }
  }
}
