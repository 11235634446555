@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/fonts' as font; 

.facets-container {
  margin-bottom: 0.75rem;

  input[type="checkbox"] {

    + label {
      @include font.fs-px(18);
      margin-bottom: 0.75rem; 
      padding-left: 1.75rem; 
    }

    &:checked {
      + label { 
        
        &::before {
          border-color: color.$v2-into-turquoise-100; 
          background-color: color.$v2-into-turquoise-100;
        }
      }
    }
  }
}