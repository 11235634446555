@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;

.downloads {
  &_item {
    flex-wrap: nowrap;

    @include dimension.tabletAndDesktop {
      width: fit-content;
    }
  }

  .filename {
    flex-grow: 1;

    @include dimension.tabletAndDesktop {
      flex-grow: 0;
    }
  }

  svg {
    width: 15px;
    flex-shrink: 0;
    margin-right: 0.75rem;
  }

  margin-left: -0.938rem;
  margin-right: -0.938rem;

  @include dimension.desktop {
    margin-left: 0;
    margin-right: 0;
  }

  &-content {
    h2 {
      background-color: color.$v2-into-turquoise-100;
      color: color.$white;
      border-radius: 0.375rem 0.375rem 0 0;
      padding: 0.625rem 1.5rem;

      @include dimension.tabletAndMobile {
        border-radius: 0;
      }
    }

    @include dimension.tabletAndMobile {
      border-radius: 0;
      border: none;
      border-bottom: 2px solid color.$v2-into-turquoise-100;
    }
  }

  &-list {
    padding: 1.25rem 1.5rem;

    .downloads_item {
      span {
        span {
          color: color.$copy-text;
        }

        svg {
          margin-right: 0;
          width: 20px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
