@use 'sass:math';

@mixin fs-rem($rem-size) {
  font-size: font-px-size($rem-size) + px;
  font-size: $rem-size + rem;
}

@function font-px-size($rem-size, $base-font-size-px: 16) {
  @return $base-font-size-px * $rem-size;
}

@mixin fs-px($px-size) {
  font-size: $px-size + px;
  font-size: font-rem-size($px-size) + rem;
}

@function font-rem-size($px-size, $base-font-size-px: 16) {
  @return #{math.div($px-size, $base-font-size-px)};
}

/*
 * Light
 */
@font-face {
  font-family: 'Neo Sans';
  font-weight: 300;
  src: url('../../../public/next-assets/fonts/NeoSans/681fbcbc-1907-441a-893a-fc00ecea0267.eot?#iefix');
  src: url('../../../public/next-assets/fonts/NeoSans/681fbcbc-1907-441a-893a-fc00ecea0267.eot?#iefix')
      format('eot'),
    url('../../../public/next-assets/fonts/NeoSans/e0bd859d-12b1-41c1-9b67-003688d01609.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSans/a21a792c-051c-4059-8719-ecc8749c99a6.woff')
      format('woff'),
    url('../../../public/next-assets/fonts/NeoSans/feee8e94-28a0-416a-88d3-db3912effbc4.ttf')
      format('truetype');
  font-display: block;
}

/*
* Regular
*/
@font-face {
  font-family: 'Neo Sans';
  font-weight: 400;
  src: url('../../../public/next-assets/fonts/NeoSans/2959d89a-a257-4ce7-9cda-63e00f275369.eot?#iefix');
  src: url('../../../public/next-assets/fonts/NeoSans/2959d89a-a257-4ce7-9cda-63e00f275369.eot?#iefix')
      format('eot'),
    url('../../../public/next-assets/fonts/NeoSans/a53085dd-4493-4430-8349-fd63d6f3e5bc.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSans/a32c3a17-3242-461c-a048-ca8e775582b1.woff')
      format('woff'),
    url('../../../public/next-assets/fonts/NeoSans/97316a7d-9db1-4dc1-bd87-e24103d88a81.ttf')
      format('truetype');
  font-display: block;
}

/*
* Regular Italic
*/
@font-face {
  font-family: 'Neo Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../../../public/next-assets/fonts/NeoSans/f4875de8-7883-4489-958a-e2479fe77d29.eot?#iefix');
  src: url('../../../public/next-assets/fonts/NeoSans/f4875de8-7883-4489-958a-e2479fe77d29.eot?#iefix')
      format('eot'),
    url('../../../public/next-assets/fonts/NeoSans/9ff15e66-152b-4d48-a338-ab8c95431ca7.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSans/740562f3-2434-4080-8b87-ac8084c724d4.woff')
      format('woff'),
    url('../../../public/next-assets/fonts/NeoSans/cd590b5e-4e72-4828-955d-a84944bb3eda.ttf')
      format('truetype');
  font-display: block;
}

/*
* Medium
*/
@font-face {
  font-family: 'Neo Sans';
  font-weight: bold;
  src: url('../../../public/next-assets/fonts/NeoSans/85c8556d-8a76-4037-a2e2-0f5fd9dabc03.eot?#iefix');
  src: url('../../../public/next-assets/fonts/NeoSans/85c8556d-8a76-4037-a2e2-0f5fd9dabc03.eot?#iefix')
      format('eot'),
    url('../../../public/next-assets/fonts/NeoSans/f056e9e7-3bb5-4f21-b861-ed2a47a0700b.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSans/b0eddbbb-9e8e-4e08-bde1-965bb945f73a.woff')
      format('woff'),
    url('../../../public/next-assets/fonts/NeoSans/b2931674-c992-42a0-aa22-e9cb15d3383d.ttf')
      format('truetype');
  font-display: block;
}
@font-face {
    font-family: "Neo Sans";
    /*Neo Sans W01 Medium*/
    font-weight: 500;
    font-style: normal;
    src: url("../../../public/next-assets/fonts/NeoSans/f056e9e7-3bb5-4f21-b861-ed2a47a0700b.woff2") format("woff2"),
        url("../../../public/next-assets/fonts/NeoSans/b0eddbbb-9e8e-4e08-bde1-965bb945f73a.woff") format("woff")
}
@font-face {
    font-family: 'Neo Sans Medium';
    src: url("../../../public/next-assets/fonts/NeoSans/NeoSansStd-Medium.otf") format("opentype"), 
    url("../../../public/next-assets/fonts/NeoSans/NeoSansStd-Medium.woff") format("opentype"), 
    url("../../../public/next-assets/fonts/NeoSans/NeoSansStd-Medium.eot") format("opentype");
}


/*
* Bold
*/
@font-face {
  font-family: 'Neo Sans';
  font-weight: 700;
  src: url('../../../public/next-assets/fonts/NeoSans/a0b14394-4dd9-4421-98cf-dfe2f8dc6251.eot?#iefix');
  src: url('../../../public/next-assets/fonts/NeoSans/a0b14394-4dd9-4421-98cf-dfe2f8dc6251.eot?#iefix')
      format('eot'),
    url('../../../public/next-assets/fonts/NeoSans/82a34ab2-92d9-48c4-989d-50d5bd54fdd1.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSans/ecedcc38-3aec-48a5-a693-fbfac59dd0ed.woff')
      format('woff'),
    url('../../../public/next-assets/fonts/NeoSans/2974017c-634d-4238-9381-e3f0b95a150c.ttf')
      format('truetype');
  font-display: block;
}

/*
* Black
*/
@font-face {
  font-family: 'Neo Sans';
  font-weight: 800;
  src: url('../../../public/next-assets/fonts/NeoSans/a18848f0-fc35-4303-b859-765085dc8136.eot?#iefix');
  src: url('../../../public/next-assets/fonts/NeoSans/a18848f0-fc35-4303-b859-765085dc8136.eot?#iefix')
      format('eot'),
    url('../../../public/next-assets/fonts/NeoSans/35cfb856-b935-45e7-b54f-b60e4b23e03c.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSans/424ba691-179e-4d47-8ab9-e7eedc23c30f.woff')
      format('woff'),
    url('../../../public/next-assets/fonts/NeoSans/fc12c0b1-38e2-4dba-acfd-e2c26fd997a4.ttf')
      format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Hairline.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Hairline.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-HairlineItalic.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-HairlineItalic.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Thin.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Thin.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 200;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-ThinItalic.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-ThinItalic.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Light.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Light.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-LightItalic.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-LightItalic.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Regular.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Regular.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Italic.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Italic.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Medium.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Medium.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-MediumItalic.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-MediumItalic.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Semibold.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Semibold.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-SemiboldItalic.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-SemiboldItalic.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Bold.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Bold.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-BoldItalic.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Heavy.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Heavy.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-HeavyItalic.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-HeavyItalic.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-Black.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-Black.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  src: url('../../../public/next-assets/fonts/Lato/Lato-BlackItalic.woff2') format('woff2'),
    url('../../../public/next-assets/fonts/Lato/Lato-BlackItalic.woff') format('woff');
  font-display: block;
}

/*
 * Light
 */
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 300;
  src: url('../../../public/next-assets/fonts/NeoSansPro/8b1edbbc-0a28-49be-bf12-f113e2a40561.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/274b913a-8ed6-4fb5-9d39-c1b05e9968e7.woff')
      format('woff');
  font-display: block;
}

/*
* Light Italic
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 300;
  font-style: italic;
  src: url('../../../public/next-assets/fonts/NeoSansPro/c3504ac0-3e4d-49fa-8725-0c5e1bd9dff8.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/7362755c-ad2c-452d-9641-cd2552b44f8e.woff')
      format('woff');
  font-display: block;
}

/*
* Regular
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 400;
  src: url('../../../public/next-assets/fonts/NeoSansPro/58ac77d8-6599-49ab-bcb8-245c8b170f3e.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/89acbc46-339c-4345-bdd2-e076a14d6f64.woff')
      format('woff');
  font-display: block;
}

/*
* Italic (Regular)
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 400;
  font-style: italic;
  src: url('../../../public/next-assets/fonts/NeoSansPro/f9836717-c135-4dd4-985e-cd651a188576.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/70089287-753e-4f48-bd86-96eeb892128c.woff')
      format('woff');
  font-display: block;
}

/*
* Medium
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: bold;
  src: url('../../../public/next-assets/fonts/NeoSansPro/85aa546b-6bae-4d49-8744-c50d79450f98.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/4204cc02-94d8-40bd-b2cf-1d4f44b7156b.woff')
      format('woff');
  font-display: block;
}

/*
* Medium Italic
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: bold;
  font-style: italic;
  src: url('../../../public/next-assets/fonts/NeoSansPro/5a35cf38-e92a-41b6-a90b-c303faea19a5.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/9edb5547-36de-4caa-93d6-16eacae41510.woff')
      format('woff');
  font-display: block;
}

/*
* Bold
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 700;
  src: url('../../../public/next-assets/fonts/NeoSansPro/75e2c08e-0a5a-4213-99ac-bb809c7f461b.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/75ef53f2-1e42-44ae-bdc5-cd99c71cb044.woff')
      format('woff');
  font-display: block;
}

/*
* Bold Italic
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 700;
  font-style: italic;
  src: url('../../../public/next-assets/fonts/NeoSansPro/476802b9-5e38-4e68-8b3e-90dc3aa3c1f9.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/b16780c3-2a85-4bde-87d4-e907afc47d65.woff')
      format('woff');
  font-display: block;
}

/*
* Black
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 800;
  src: url('../../../public/next-assets/fonts/NeoSansPro/4bf4b281-e33f-43fe-a738-10a938b18c69.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/86b16d67-fdb4-45e2-976d-0128fc9258a8.woff')
      format('woff');
  font-display: block;
}

/*
* Black Italic
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 800;
  font-style: italic;
  src: url('../../../public/next-assets/fonts/NeoSansPro/f594d7e8-eb01-4f80-8924-16a25cc5503d.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/cabc1418-2337-4264-a06a-35d87aabbb0e.woff')
      format('woff');
  font-display: block;
}

/*
* Ultra
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 900;
  src: url('../../../public/next-assets/fonts/NeoSansPro/352cd8c4-cd65-4e20-9df5-419231d7a60e.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/7c4e19fb-5dc1-4cc5-a7f8-a5610f351fc3.woff')
      format('woff');
  font-display: block;
}

/*
* Ultra Italic
*/
@font-face {
  font-family: 'Neo Sans Pro';
  font-weight: 900;
  font-style: italic;
  src: url('../../../public/next-assets/fonts/NeoSansPro/bcbd3527-b148-4cff-a73b-e38a35474018.woff2')
      format('woff2'),
    url('../../../public/next-assets/fonts/NeoSansPro/4be75d74-2550-4478-84f9-03cc19c54a62.woff')
      format('woff');
  font-display: block;
}

// Font Family
$font-ns-pro: 'Neo Sans Pro', 'Helvetica Neue', Arial;
$font-lato: 'Lato', 'Helvetica Neue', Arial;

// Font Weight
$fw-normal: 400;
$fw-bold: 700;

// Font Size
$font-size-base: 1rem;
