@use '@/styles/utils/dimensions' as dimension;
@use '@/styles/utils/fonts' as font;

.search-title {
  text-align: left;
  display: inline-block;
  margin: 0;

  @include dimension.tabletAndMobile {
    @include font.fs-rem(1.125);
    padding-left: 0;
  }
}
