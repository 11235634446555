@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;
@use '@/styles/utils/fonts' as font;

.key_facts_bar {
  color: color.$white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 0.25rem;
  min-height: 4.063rem;

  @include dimension.tabletAndMobile {
    flex-direction: column;
  }

  .stat_item {
    font-family: font.$font-ns-pro;
    border-right: 2px solid color.$white;
    flex-basis: 0;
    flex-grow: 1;
    flex-direction: row;

    @include dimension.tabletAndMobile {
      border-right: unset;
      display: flex;
      justify-content: space-between;
    }

    .stat_value {

      @include dimension.tabletAndMobile {
        display: inline-flex;
        min-width: 3.75rem;
      }
    }
  }
  :last-child.stat_item {
    border-right: unset;
  }
}
