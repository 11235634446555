@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;
@use '@/styles/utils/fonts' as font;

.branding {
  @include dimension.mobile {
    flex-wrap: wrap;
  }

  font-family: font.$font-lato;
  color: color.$v2-grey-1;
  letter-spacing: 0.031rem;

  .brand_name {
    width: 100%;
    margin: 0px;

    @include dimension.mobile {
      margin-bottom: 0.438rem;
    }
  }

  .logo {
    height: 4.75rem;

    @include dimension.mobile {
      width: 100%;
      margin-bottom: 0.75rem;
    }

    img {
      height: 4.75rem;
      object-fit: contain;
    }
  }

  .highlight_ranking {
    font-family: font.$font-lato;

    @include dimension.mobile {
      margin: 0px;
    }
  }
}
