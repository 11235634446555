$xl-desktop-width: 1440px; // XXL
$xxl-desktop-width: 1600px;
$large-desktop-width: 1200px; // XL
$tablet-landscape-width: 1024px;
$desktop-width: 992px; // LG
$tablet-width: 768px; // MD
$mobile-width: 576px; // SM
$mobile-portrait-width: 480px;

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin smallDesktop {
  @media (max-width: #{$large-desktop-width - 1px}) {
    @content;
  }
}

@mixin largeDesktop {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

@mixin xxLargeDesktop {
  @media (min-width: #{$xxl-desktop-width}) {
    @content;
  }
}
@mixin xLargeDesktop {
  @media (min-width: #{$xl-desktop-width}) {
    @content;
  }
}

@mixin tabletAndDesktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tabletLandscapeAndDesktop {
  @media (min-width: #{$tablet-landscape-width + 1px}) {
    @content;
  }
}

@mixin mobileTabletAndDesktop {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tabletLandscape {
  @media (max-width: #{$tablet-landscape-width}) {
    @content;
  }
}

@mixin tabletAndMobile {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobilePortrait {
  @media (max-width: #{$mobile-portrait-width}) {
    @content;
  }
}

@mixin smallMobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}
