@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;

.campus-location {
  border: 1px solid color.$v2-grey-5;
  margin-bottom: 0.5rem;

  @include dimension.desktop {
    margin-bottom: 1.5rem;
  }
}
