@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/dimensions' as dimension;
@use '@/styles/utils/fonts' as font;
@use '@/styles/utils/mixins' as mixin;

.campus {
  padding: 1.5rem;
  margin-bottom: 0.75rem;

  @include dimension.desktop {
    padding: 0.75rem;
    padding-left: 3.125rem;
    margin-bottom: 1rem;
  }

  &_marker {
    @include font.fs-rem(0.75);
    font-weight: font.$fw-bold;
    line-height: 0.875rem;
    color: color.$white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: block;
    text-align: center;
    border-radius: 0.375rem 0.375rem 0 0;
    padding: 0.375rem 0.5rem;
    z-index: 1;

    @include dimension.desktop {
      position: absolute;
      bottom: 0;
      left: 5px;
      top: 50%;
      border-radius: 0;
      padding: 0;
      height: 0;
      @include mixin.transform(rotate(270deg) translateX(-50%));
      @include mixin.transform-origin-offset(0, 0);
    }
  }

  &_content {
    @include dimension.desktop {
      order: 1;
    }
  }

  &_image {
    min-height: 200px;

    @include dimension.desktop {
      order: 2;
    }

    img {
      border-radius: 0.25rem;

      @include dimension.desktop {
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }

    @include dimension.tabletAndMobile {
      margin-bottom: 0.75rem;
    }
  }

  &_address {
    border: 0;
    background: transparent;
    text-align: left;
    padding: 0;

    svg {
      flex-shrink: 0;
    }

    span {
      display: inline-block;
      text-decoration: underline;
      padding-left: 0.625rem;
    }
  }
}

.major {
  @include dimension.desktop {
    min-height: 7rem;
  }

  &::before {
    content: '';
    background-color: color.$v2-grey-5;
    position: absolute;
    display: inline-block;
    top: 1.5rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 1.625rem;
    width: calc(100% - 3rem);
    border-radius: 0.375rem 0.375rem 0 0;

    @include dimension.desktop {
      right: auto;
      top: 0;
      margin: 0;
      height: 100%;
      width: 1.5rem;
      border-radius: 0.375rem 0 0 0.375rem;
    }
  }

  .campus_image {
    img {
      @include dimension.tabletAndMobile {
        border-radius: 0 0 0.25rem 0.25rem;
      }
    }
  }
}

.disabled {
  cursor: default;

  span {
    text-decoration: none;
  }
}
