@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;
@use '@/styles/utils/fonts' as font;
@use '@/styles/utils/mixins' as mixin;
@use '@/styles/functions/z-index' as zIndex;

.slide-out-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: zIndex.into-z-index('cover', 'navigation');
  @include mixin.scale(0);
  @include mixin.transition(transform 0s 0.195s);

  &.slide-out-panel-visible {
    -webkit-transform: scale(1) translateZ(0);
    transform: scale(1) translateZ(0);
    @include mixin.transition(transform 0s);
  }
}

.slide-out-panel-shadow {
  position: absolute;
  top: 0;
  height: 100%;
  opacity: 0;
  z-index: zIndex.into-z-index('cover', 'navigation');
  left: 0;
  display: block;
  width: 100%;
  background-color: color.$black;
  -webkit-tap-highlight-color: color.$black;
  @include mixin.transition(opacity 0.195s, transform 0s 0.195s);

  &.slide-out-panel-shadow-visible {
    opacity: 0.55;
    @include mixin.transition(opacity 0.225s, transform 0s);
  }
}

.slide-out-panel-content {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: color.$white;
  outline-width: 0;
  right: 100%;
  width: calc(100% - 50px);
  @include mixin.transition(transform 0.195s);
  z-index: zIndex.into-z-index('cover', 'navigation');

  &.slide-out-panel-content-visible {
    @include mixin.transform(translate(100%, 0));
    @include mixin.transition(transform 0.225s);
  }
}

.slide-out-panel-title {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 3.188rem;
  padding: 0.938rem;
  height: 80px;
  text-align: left;
  background: color.$white;
  position: relative;

  h3 {
    font-family: font.$font-lato;
  }
}

.slide-out-panel-close {
  @include mixin.defaultIconButton(
    3.125rem,
    3.125rem,
    0,
    0,
    absolute,
    0,
    0,
    '/next-assets/icons/times-solid.svg',
    1rem
  );
  top: 0;
  right: 0;
  &:focus {
    @include mixin.box-shadow(0, 0, 0, 0.2rem, color.$v2-neutral-yellow-100, false);
  }
}

.slide-out-panel-children {
  padding: 0.625rem 1rem;
}

.mobile-filters-panel {
  height: calc(100% - 131px);
}

