@use '~@into-discovery/browsewebshared/src/styles/utils/colors' as color;

.results {
  > :not(:last-child) {
    margin-bottom: 1.5rem;
  }

  a:not([role="link"]) {
    text-decoration: none; 
    color: color.$v2-into-turquoise-100;
    border-radius: 0.25rem;

    &:visited {
      color: color.$v2-purple-100;
    }

    &:active, 
    &:hover,
    &:visited:hover {
      color: color.$v2-into-turquoise-200; 
      text-decoration: underline;
    }

    &:active:hover,
    &:focus,
    &:focus-within,
    &:focus-visible,
    &:focus:hover,
    &:focus-within:hover,
    &:focus-visible:hover,
    &:visited:focus:hover,
    &:visited:focus-within:hover,
    &:visited:focus-visible:hover {
      color: color.$v2-into-turquoise-100;
      box-shadow: 0px 0px 0px 1px color.$v2-neutral-yellow-100;
      outline: none;
      text-decoration: underline;
    }
  }

  a[role="link"] {
    width: auto;
  }
}